import axios from 'axios';
import request from "oc-request";
import CartPositionList from '../cart-position-list/cart-position-list'
export default new class Cupon{
    constructor()
    {
        const _this = this;
        document.addEventListener(
            'click',
            function (e)
            {
                let target = e.target.closest('.btn-cupon');

                if (target) {
                    let form = target.parentElement;

                    if (form && form.getAttribute('data-event') == 'add' ) {
                        _this.applyCupon(form);
                    } else {
                        if (form && form.getAttribute('data-event') == 'remove') {
                            _this.removeCupon(form);
                        }
                    }
                }

            }
        )
    }

    applyCupon(form)
    {
        const _this = this;
        const headers = {
            'X-Requested-With': 'XMLHttpRequest',
            'Content-Type': 'application/json',
            'X-OCTOBER-REQUEST-HANDLER': 'Cart::onAddCoupon',
        }

        const searchParams = new URLSearchParams(window.location.search);

        form.addEventListener('submit', function (e) {
            e.stopPropagation();
            e.preventDefault();
            _this.loaderHandler(form, '.loader-container');
            axios.post(
                window.location.href,
                {
                    coupon: form.querySelector('#cupon-id').value
                },
                {
                    headers: headers
                }
            ).then((response) => {
                const { status } = response;
                const { data } = response;
                if (status == 200) {
                    console.log(response)
                    _this.loaderHandler(form, '.loader-container');
                    if (data.message) {
                        _this.alertHandler('#alerta', data.message);
                    }
                    CartPositionList.setOnce(false);
                    if (searchParams.has('activeShipping')) {
                        CartPositionList.setActiveFrontendShippingType()
                    } else {
                        CartPositionList.updatePrice(data.data)
                    }
                }
            })
                .catch((response)=>{
                    const { data } = response;
                    _this.alertHandler('#alerta', data.message)
                    _this.loaderHandler(form, '.loader-container');
                })
                .finally(()=>{
                })
        })
    }

    removeCupon(form)
    {
        const _this = this;
        const headers = {
            'X-Requested-With': 'XMLHttpRequest',
            'Content-Type': 'application/json',
            'X-OCTOBER-REQUEST-HANDLER': 'Cart::onRemoveCoupon',
        }

        const searchParams = new URLSearchParams(window.location.search);

        form.addEventListener('submit', function (e) {
            e.preventDefault();
            e.stopPropagation();
            _this.loaderHandler(form, '.loader-container');
            axios.post(
                window.location.href,
                {
                    coupon: form.querySelector('#cupon-id').value
                },
                {
                    headers: headers
                }
            ).then((response) => {
                const { status } = response;
                const { data } = response;
                if (status == 200) {
                    console.log(response)
                    _this.loaderHandler(form, '.loader-container');
                    if (data.message) {
                        _this.alertHandler('#alerta', data.message);
                    }
                    CartPositionList.setOnce(false);
                    if (searchParams.has('activeShipping')) {
                        CartPositionList.setActiveFrontendShippingType()
                    } else {
                        CartPositionList.updatePrice(data.data)
                    }
                }
            })
                .catch((response)=>{
                    const { data } = response;
                    _this.alertHandler('#alerta', data.message)
                    _this.loaderHandler(form, '.loader-container');
                })
                .finally(()=>{
                })
        })
    }

    onClear()
    {
        //$.request('Cart::onClearCouponList');
    }

    loaderHandler(form, selector)
    {
        let loader = form.querySelector(`${selector}`);
        if (loader.classList.contains('is-active')) {
            loader.classList.remove('is-active');
        }else {
            loader.classList.add('is-active');
        }
    }

    alertHandler(selector, text)
    {
        setTimeout(() => {
            let alert = document.querySelector(`${selector}`);
            let textNode = document.createTextNode(text);
            alert.append(textNode);
            alert.classList.add('show');
            setTimeout(() => {
                alert.classList.remove('show');
            }, 5000)
        }, 300)
    }
}()