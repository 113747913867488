let slider = document.querySelector('.portadas');
if (slider != null && slider.children.length > 0) {
    let portadas = document.querySelector(".portadas");
    let options = {
        cellSelector: ".slide-cell",
        pageDots: false,
        percentPosition: false,
        lazyLoad: true,
        wrapAround: true,
        setGallerySize: true,
        autoPlay: 10000,
        imagesLoaded: true,
        pauseAutoPlayOnHover: false,
        prevNextButtons: true,
        adaptiveHeight: true,
        resize: true,

        on: {
            ready: function () {
                let spinner = document.querySelector('#spinner');
                let btns = document.querySelectorAll('.portadas .flickity-button');
                let dots = document.querySelector('.portadas .flickity-page-dots');
                fadeOut(spinner);
                if (this.slides.length <= 1){
                    Array.from(btns).forEach((el) => {
                        el.classList.add('d-none');
                    })
                    // dots.classList.add('d-none')
                }
                this.resize();
            }
        }
    };

    document.addEventListener('vue-mounted', function () {
        portadas = new Gallery(".portadas", options);
        // Arregla el bug del margen
        portadas.on("settle", function () {
            portadas.resize();
        });
    })

    //Agregando botones personalizados
    // var btn_izq = document.getElementsByClassName('btn-izquierda');
    // var btn_der = document.getElementsByClassName('btn-derecha');

    // btn_izq[0].onclick = function () {
    //   portadas.previous();
    // };
    // btn_der[0].onclick = function () {
    //   portadas.next();
    // };


    window.addEventListener('resize',  function () {
        // if ( matchMedia('screen and (max-width: 992px)').matches ) {
        //     portadas.options.setGallerySize = true;
        //     $('.sad-slide > .flickity-viewport').css({'height':'100%'});
        //
        // }
        //
        // if ( matchMedia('screen and (min-width: 992px)').matches ) {
        //     portadas.options.setGallerySize = false;
        //     $('.sad-slide > .flickity-viewport').css({'height':'100%'});
        // }
        portadas.resize();
    });

    // if ( matchMedia('screen and (max-width: 992px)').matches ) {
    //     portadas.options.setGallerySize = true;
    //     portadas.resize();
    // }
}
