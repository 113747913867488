import noUiSlider from 'nouislider';
export default new class noUISlider {

    constructor() {
        this.create();
    }

    create(){
        let min = 0, max = 100;

        document.addEventListener('vue-mounted', function() {
            if (document.getElementById('price-range')){

                //form
                let form = document.getElementById('price-range');

                //Checking if there is already a price set
                var searchParams = new URLSearchParams(window.location.search);
                var param = null;
                //let maxValue = document.querySelector('input[name=maxprice]').value==0?100:document.querySelector('input[name=maxprice]').value;
                //let minValue = document.querySelector('input[name=minprice]').value==0?0:document.querySelector('input[name=minprice]').value;
                if(searchParams.has('price')){
                    param = searchParams.get('price');
                    min = param.split(',')[0];
                    max = param.split(',')[1];

                    document.querySelector('input[name=maxprice]').setAttribute('value', max);
                    document.querySelector('input[name=minprice]').setAttribute('value', min);
                }


                form.addEventListener('submit', function(e){
                    e.preventDefault();
                    let min = document.querySelector('input[name=minprice]').value;
                    let max = document.querySelector('input[name=maxprice]').value;
                    addUrlParameter(
                        'price',
                        [min, max]
                    );
                })


                /*let noui = noUiSlider.create(slider, {
                    start: [min, searchParams.has('price')?max:Math.ceil(maxValue)],
                    connect: true,
                    tooltips: true,
                    range: {
                        'min': Math.ceil(minValue)==Math.ceil(maxValue)?0:Math.floor(minValue),
                        'max': Math.ceil(maxValue)
                    }
                });

                noui.on('change', function () {
                    //Do the logic
                    addUrlParameter(
                        'price',
                        [noui.get()[0], noui.get()[1]]
                    );
                })*/
            }
        })
    }
}
