import request from 'oc-request';
export default new class PreviewButton {

    constructor() {
        const _this = this;
        this._targettedModal,
        this.modalLoadingClass = "is-modal-loading",
        this.modalActiveClass = "is-modal-active";

        document.addEventListener('vue-mounted', function () {
            _this.initModal();
        })
    }

    showModal(el, id){
        const _this = this;
        _this._targettedModal = document.querySelector('[data-modal-name="'+ el + '"]').parentNode.parentNode;
        _this._targettedModal.classList.add(_this.modalLoadingClass);
        request.sendData('onInit', {
            data: {
                'preview_item_id': id
            },
            update: {
                'misc/modal/modal': `.modal-row`
            },
            success: function(response) {
                lazyLoadInstance.update();
                _this._targettedModal.classList.add(_this.modalActiveClass);
                document.dispatchEvent(new Event('refresh-close-buttons'))
                _this._targettedModal.classList.remove(_this.modalLoadingClass);
            }
        })
    }

    hideModal(el){
        const _this = this;
        if(el.hasAttribute('data-modal-dismiss')) {
            _this._targettedModal.classList.remove(_this.modalActiveClass);
        }
    }

    initModal(){

        const _this = this,
            _triggers = document.querySelectorAll('[data-modal-trigger]'),
            _dismiss = document.querySelectorAll('[data-modal-dismiss]');

        Array.from(_triggers).forEach((el) => {
            el.addEventListener('click', function(e) {
                let id = el.getAttribute('data-product-id')
                _this.showModal(el.dataset.modalTrigger, id)
            })
        })
        document.addEventListener('refresh-close-buttons', function() {
            Array.from(_dismiss).forEach((el) => {
                document.addEventListener('click', function(e) {
                    let target = e.target.hasAttribute('data-modal-dismiss');
                    if (target) {
                        _this.hideModal(el);
                    }
                })
            })
        })
    }
}();
