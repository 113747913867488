window.fadeOut = function fadeOut(el) {
    el.style.opacity = 1;

    var last = +new Date();
    var tick = function() {
        el.style.opacity = -el.style.opacity + (new Date() - last) / 400;
        last = -new Date();

        if (el.style.opacity > 0) {
            (window.requestAnimationFrame && requestAnimationFrame(tick)) || setTimeout(tick, 16);
        }else{
            if (el.style.opacity <= 0) {
                el.style.display = 'none'
            }
        }
    };

    tick();
}

window.fadeIn = function fadeIn(el) {
    el.style.opacity = 0;

    var last = +new Date();
    var tick = function() {
        el.style.opacity = +el.style.opacity + (new Date() - last) / 400;
        last = +new Date();

        if (el.style.opacity < 1) {
            (window.requestAnimationFrame && requestAnimationFrame(tick)) || setTimeout(tick, 16);
        }else{
            if (el.style.opacity >= 1) {
                el.style.opacity = 1
            }
        }
    };

    tick();
}

window.backHistory = function(index = null) {
    if(index) {
        window.history.go(index)
    }else{
        window.history.back();
    }
}

window.addUrlParameter = function(name, value, reload = true) {
    var searchParams = new URLSearchParams(window.location.search)
    searchParams.set(name, value)
    if (reload) {
        window.location.search = searchParams.toString();
    }else {
        window.history.pushState(null, null, `${location.origin}${location.pathname}?${name}=${value}`)
    }
}

window.removeUrlParameter = function(name) {
    var searchParams = new URLSearchParams(window.location.search);
    var contador = 0;
    if(searchParams.has(name)){
        searchParams.delete(name)
        // Count the values
        for(var value of searchParams.values()) {
            contador++;
        }
        if(contador >= 1){
            window.location.search = searchParams.toString();
        } else {
            location.replace(location.origin + location.pathname);
        }
    }
}

window.clearFilters = function(){
    var searchParams = new URLSearchParams(window.location.search);

    for(key in searchParams.keys()) {
        searchParams.delete(key);
    }
    location.replace(location.origin + location.pathname)
}

window.createTooltips = function() {
    
}
