window.LazyLoad = require('vanilla-lazyload');
window.Gallery = require('flickity-imagesloaded');
window.axios = require("axios");
window.oc = require('oc-request');
window.Packery = require("packery");
// window.SlimSelect = require('slim-select');
require('flickity');
require("flickity-fullscreen");
require("flickity-as-nav-for");
require("flickity-bg-lazyload");
require('./global-functions/functions');
require('./components/preview_button/preview_button')
require('./components/main-sider/main-slider');
require('./components/button-add-to-cart/button-add-to-cart');
require('./components/change-qty/button-change-quantity');
require('./components/cart-position-list/cart-position-list');
require('./components/cart-link-header/cart-link-header');
require('./components/product-list/product-list');
require('./components/search-page/search-result');
require('./components/pagination/_pagination');
require('./components/location-select/location-select');
require('./components/product-sorting/product-sorting');
require('./components/checkout-form/checkout-form-validation');
require('./components/wish-list/wishList');
require('./components/contactForm/contactForm');
require('./components/auth/auth');
require('./components/cupons/cupon');
require('./components/make-reviews/reviews');
require('./components/no-ui-slider/noUISlider');

window.lazyLoadInstance = new LazyLoad({
    elements_selector: ".lazyload"
    // ... more custom settings?
});

document.addEventListener('vue-mounted', function () {
    lazyLoadInstance.update();
})

// document.addEventListener('readystatechange', event => {
//     if (event.target.readyState === "interactive") {
//         // initLoader();
//     }
//     else if (event.target.readyState === "complete") {
//         Array.from(document.querySelectorAll('main.page')).forEach((el) => {
//             let parent = el.parentNode;
//             parent.removeChild(el);
//         })

//         setTimeout(() => {
//             document.querySelectorAll('[data-loader="spinner"]').forEach( el => {
//                 el.classList.add('d-none');
//             })
//         }, 1500)
//     }
// });
window.onbeforeunload = function() {
    document.querySelectorAll('[data-loader="spinner"]').forEach( el => {
        el.classList.remove('d-none');
    })
};
document.body.onload = (e) => {
    document.querySelectorAll('[data-loader="spinner"]').forEach( el => {
        el.classList.add('d-none');
    })
}
