import request from 'oc-request';
import SlimSelect from "slim-select";
export default new class Location {
    constructor() {
        //this.slims()
        this.eventHandlers();
    }

    eventHandlers() {
        const _this = this;
        ['reload', 'readystatechange'].forEach((e) => {
            document.addEventListener(e, function () {
                let slims = document.querySelectorAll(`.location-country`);
                Array.from(slims).forEach((el) => {
                    el.addEventListener('change', function(e) {
                        _this.request(el);
                    })
                })
            })
        })
    }

    slims(){
        document.addEventListener('readystatechange', function(e){
            if (e.target.readyState == 'complete') {
                //Inicializo todos los selcts con slim-select
                const selects = document.querySelectorAll('select')
                selects.forEach((selectElement) => {
                    new SlimSelect({
                        select: selectElement,
                        showSearch: false
                    })
                })
            }
        })
    }

    request(el){
        const _this = this;
        var id = el.parentNode.parentNode.parentNode.getAttribute('id')
        el.setAttribute('disabled', 'true');
        request.sendData('onInit',
            {
                data: {
                    country: el.value,
                },
                update: {'cart/location-select/location-select': `#${id}`},
                complete: function () {
                    return document.dispatchEvent(new Event('reload'));
                    //return el.removeAttribute('disabled')
                }
            })
    }
}
