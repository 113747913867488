import CartPositionList from "../cart-position-list/cart-position-list";

export default new class ButtonChangeQuantity {
  constructor() {
    /* selectors */
    this.productQuantitySelector = '.product-detailed__btn-qty';
    this.btnQuantityChangeSelector = '.product-detailed__btn-qty-change';
    this.quantitySelector = '.product-detailed__btn-qty-input';

    /* variables */
    this.checkQuantityRegExp = /^\+?(0|[1-9]\d*)$/;

    this.eventHandlers();
  }

  eventHandlers() {
    if (!document.querySelector(`${this.productQuantitySelector}`)) {
      return;
    }

    const _this = this;

    document.addEventListener('click', function (e) {
        if (e.target.closest(`${_this.btnQuantityChangeSelector}`)){
                const $btn = e.target.closest(`${_this.btnQuantityChangeSelector}`),
                    $productQuantityInput = $btn.parentNode.querySelector(_this.quantitySelector),
                    maxQuantity = parseInt($productQuantityInput.getAttribute('max'));
                let productQuantity = parseInt($productQuantityInput.getAttribute('value'));

                const isQuantityValid = _this.checkQuantityValidity(productQuantity);

                if (isQuantityValid) {
                    if ($btn.getAttribute('data-qty') === 'minus') {
                        _this.reduceQuantity($productQuantityInput, productQuantity, maxQuantity, $btn);
                    } else if ($btn.getAttribute('data-qty') === 'plus') {
                        _this.addQuantity($productQuantityInput, productQuantity, maxQuantity, $btn);
                    }
                    if ($btn.getAttribute('data-ajax') === 'updateTotal') {
                        CartPositionList.sendRequestUpdateItem($btn);
                    }
                }
            }
        }, true);


        let qtySelector = document.querySelector(`${this.quantitySelector}`);
        qtySelector.addEventListener('change', function (e) {
            const $input = e.target;

            if($input.getAttribute('data-ajax') === 'updateTotal') {
                CartPositionList.sendRequestUpdateItem($input);
            }
        });
  }

  checkQuantityValidity(quantity) {
    const _this = this;

    return _this.checkQuantityRegExp.test(quantity);
  }

  addQuantity(productQuantityInput, productQuantity, maxQuantity, $btn) {
    const newValue = +productQuantity + 1,
      $minBtn = $btn.parentNode.querySelector(`button[data-qty='minus']`);
    if (newValue > maxQuantity) {
      $btn.setAttribute('disabled', 'disabled');
      return;
    }

    if (newValue > 1) {
      $minBtn.setAttribute('disabled', false);
    }

    if (newValue >= maxQuantity) {
      $btn.setAttribute('disabled', 'disabled');
    }

    productQuantityInput.setAttribute('value', +productQuantity + 1);
  }

  reduceQuantity(productQuantityInput, productQuantity, maxQuantity, $btn) {
    const newValue = +productQuantity - 1,
      $maxBtn = $btn.parentNode.querySelector(`button[data-qty='plus']`);

    if (productQuantity > 1) {
      productQuantityInput.setAttribute('value', newValue);
    }

    if (newValue <= 1) {
      $btn.setAttribute('disabled', 'disabled');
    }

    if (newValue < maxQuantity) {
      $maxBtn.setAttribute('disabled', false);
    }
  }
}();
