import CatalogProductList from '../product-list/product-list';
// import CatalogTagList from './../../product/catalog-tag-list/catalog-tag-list';
// import PromoBlockProductList from './../../product/promo-block-product-list/promo-block-product-list';
// import PromoBlockList from './../../promo-block/promo-block-list/promo-block-list';
// import catalogPanel from './../../product/catalog-panel/catalog-panel';
import searchResult from '../search-page/search-result';

export default new class Pagination {
    constructor() {
        this.btnPage = 'page-link';
        this.activeClass = 'active';
        this.catalogProductListLabel = 'catalog-product-list';
        this.catalogTagListLabel = 'catalog-tag-list';
        this.promoBlockListLabel = 'promo-block-list';
        this.promoBlockProductListLabel = 'promo-block-product-list';
        this.searchProductListLabel = 'search-result-list';

        this.eventHandlers();
    }

    eventHandlers() {

        document.addEventListener('click', (e) => {

            if (e.target.closest(`.${this.btnPage}`)){
                e.preventDefault();
                e.stopPropagation();
                const button = e.target.closest(`.${this.btnPage}`),
                    page = button.getAttribute('data-page'),
                    label = document.querySelector('ul.pagination').parentNode.getAttribute('aria-label');

                if (button.parentNode.classList.contains(this.activeClass)) {
                    return;
                }

                switch (label) {
                    case this.catalogProductListLabel:
                        CatalogProductList.updateProductList(page);
                        break;
                    case this.searchProductListLabel:
                        searchResult.updateProductList(page);
                        break;
                    case this.catalogTagListLabel:
                        CatalogTagList.updateProductList(page);
                        break;
                    case this.promoBlockListLabel:
                        PromoBlockList.updatePromoBlockList(page);
                        break;
                    case this.promoBlockProductListLabel:
                        PromoBlockProductList.updateProductList(page);
                        break;
                }
            }
        });
    }

} ();
