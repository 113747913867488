import request from 'oc-request';
export default new class WishList{

    constructor() {
        this.selector = 'btn-add-to-wish-list';
        this.clearButton = 'clearWishList';
        this.deleteWishItemButton = 'wish__delete-btn';
        this.wishItem = 'cart__item';
        this.eventListeners();
        //this.updateHeader();
    }

    eventListeners() {
        const _this = this;

        document.addEventListener('click', function (e) {
            let target = e.target.closest(`.${_this.selector}`);

            if(target) {
                let id = target.getAttribute('data-product-id');
                let preloader = document.querySelector(`div[data-loader="spinner"]`);
                let event = target.getAttribute('data-event');

                preloader.classList.remove('d-none')
                if (event == 'add') {
                    _this.addToWishList(preloader, target, id);
                }else{
                    if(event == 'remove'){
                        _this.removeFromWishList(preloader, target, id);
                    }
                }
            }
        })
        document.addEventListener('click', function(e){
            let target = e.target.closest(`.${_this.clearButton}`);

            if(target){
                _this.clearWishList();
            }
        })
        document.addEventListener('click', function(e){
            let target = e.target.closest(`.${_this.deleteWishItemButton}`);

            if(target){
                let id = target.getAttribute('data-id');
                _this.removeFromWishList(null,target, id);
            }
        })
    }

    updateHeader(){
        request.sendData('onGetData', {
            update: { 'cart/wish-list-link-header/wishlist-link-header': '.header__wish' }
        });
    }

    addToWishList(preloader, target, productId){
        const _this = this;
        let icon = target.querySelector('.fa');
        request.sendData('ProductData::onAddToWishList', {
            data: {
                'product_id' : productId
            },
            success: function() {
                setTimeout(() => {
                    preloader.classList.add('d-none')
                }, 1500)

                icon.classList.remove('fa-heart');
                icon.classList.add('fa-heart-broken');

                target.setAttribute('data-event', 'remove');

                _this.updateHeader();
            }
        })
    }

    removeFromWishList(preloader, target, productId){
        const _this = this;
        if(target){
            let item2delete = document.querySelector(`div[data-id='${target.getAttribute('data-id')}']`);
            if (item2delete) {
                let parent = item2delete.parentNode;
                if(parent.children.length == 1){
                    parent.removeChild(item2delete);
                    let messageOfEmpty = document.querySelector('#no-product');
                    let clearButton = document.querySelector(`.${this.clearButton}`);
                    clearButton.classList.add('d-none');
                    messageOfEmpty.classList.remove('d-none');
                }else{
                    parent.removeChild(item2delete);
                }
            }
        }

        request.sendData('ProductData::onRemoveFromWishList', {
            data: {
                'product_id' : productId
            },
            success: function() {
                if(preloader)
                    setTimeout(() => {
                        preloader.classList.add('d-none')
                    }, 1500)

                if(target){
                    let icon = target.querySelector('.fa');

                    icon.classList.remove('fa-heart-broken');
                    icon.classList.add('fa-heart');

                    target.setAttribute('data-event', 'add');
                }

                _this.updateHeader();
            }
        })
    }

    clearWishList(){
        const _this = this;
        let wishItems = document.querySelectorAll(`.${this.wishItem}`);

        let clearButton = document.querySelector(`.${this.clearButton}`);
        clearButton.classList.add('d-none');

        let messageOfEmpty = document.querySelector('#no-product');
        messageOfEmpty.classList.remove('d-none');

        wishItems.forEach((el) => {
            let parent = el.parentNode;
            parent.removeChild(el);
        })

        request.sendData('onClearWishList', {
            success: function () {
                _this.updateHeader();
            }
        })
    }
}();
