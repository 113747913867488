import request from 'oc-request';
export default new class CartLinkHeader {
    constructor() {
        this.updateBlock();
    }

    updateBlock(refresh = true) {

        if (document.querySelector('.cart_link_header_wrapper') == null || document.querySelector('body').hasAttribute('data-cart-is-available') != 1) {
            return;
        }

        const miniCartOpen = document.body.getAttribute('data-open');

        if(refresh)
            request.sendData('Cart::onGetData', {
                update: { 
                    'cart/cart-link-header/cart-link-header': '.cart_link_header_wrapper'
                },
                success: () => {
                    document.dispatchEvent(new Event('cart-link-header-updated'))
                }
            });
    }
}();
