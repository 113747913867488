// import CatalogPanel from './../../product/catalog-panel/catalog-panel';
import modal from '../preview_button/preview_button'
import request from 'oc-request';
export default new class CatalogProductList {
    constructor() {
        this.sortingSelector = 'select[name="sorting"]';
        this.ajaxWrapper = '_ajax_catalog_wrapper';
        this.requestData = {
            'sort': '',
            'page': 1
        };
    }

    updateProductList(page = 1) {
        this.updateRequestString(page);

        request.sendData('ProductList::onAjaxRequest', {
            update: {
                'product/catalog-product-list/ajax-catalog-product-list': `.${this.ajaxWrapper}`
            },
            success: function (response) {
                //CatalogPanel.updateCatalogPanel();
                //this.success(response);
            },
            complete: function(response) {
                Array.from(document.querySelectorAll('main.page')).forEach((el) => {
                    let parent = el.parentNode;
                    parent.removeChild(el);
                })
                lazyLoadInstance.update();
                modal.initModal();
            }
        });
    }

    updateRequestData(page) {
        this.requestData = {
            'sort': document.querySelector(`${this.sortingSelector}`).value,
            'page': page
        };
    }

    updateRequestString(page) {
        this.updateRequestData(page);

        const arKeyList = Object.keys(this.requestData);
        let sResult = new URLSearchParams(location.search);
        let setPage = false;

        arKeyList.forEach((sKey) => {
            let sValue = this.requestData[sKey];
            if (Array.isArray(sValue)) {
                sValue = sValue.join('|');
            }

            if (sKey == 'page' && sValue == 1) {
                if (sResult.has('page')) {
                    sResult.delete('page');
                    setPage = false
                }
                return;
            }

            if (sResult.toString().length > 0) {
                if (sResult.has('page')) {
                    sResult.set('page', sValue);
                    setPage = true;
                }else{
                    sResult += '&';
                }
            }

            if (sKey != 'page' || !setPage) {
                sResult = new URLSearchParams(location.search);
                sResult.set(sKey, sValue);
            }

        });

        window.history.pushState(null, null, `${location.origin}${location.pathname}${sResult?'?'+sResult:''}`);
    }
}();
