import request from "oc-request";

export default new class Review{
    constructor() {
        this.eventlistener();
    }

    eventlistener(){
        const _this = this;
        document.addEventListener('readystatechange', function(e){
            if(e.target.readyState == 'complete'){
                let form = document.querySelector('.make-review');

                if(form) {
                    form.addEventListener('submit', function (e) {
                        form.classList.add('was-validated');
                        if (form.checkValidity() == false){
                            e.preventDefault();
                            e.stopPropagation();
                            // _this.showErrors(form);
                            _this.alertHandler('#error');
                        }else{
                            e.preventDefault();
                            e.stopPropagation();
                            _this.loaderHandler(form, '.loader-container');
                            request.sendForm(form, 'MakeReview::onCreate', {
                                flash: true,
                                success: () => {
                                    form.classList.remove('was-validated');
                                    _this.alertHandler('#success');
                                    _this.loaderHandler(form, '.loader-container');
                                    form.reset();
                                }
                            })
                        }
                    })
                }
            }
        })
    }

    alertHandler(selector) {
        setTimeout(() => {
            let alert = document.querySelector(`${selector}`);
            console.log(alert);
            alert.classList.add('show');
            setTimeout(() => {
                alert.classList.remove('show');
            }, 5000)
        }, 300)
    }

    loaderHandler(form, selector) {
        let loader = form.querySelector(`${selector}`);
        if(loader.classList.contains('is-active')){
            loader.classList.remove('is-active');
        }else{
            loader.classList.add('is-active');
        }
    }
}();
